<template>
	<div>
		<BenefitingInfo typeView="HealthCounseling"></BenefitingInfo>
	</div>
</template>

<script>
export default {
	name: 'HealthCounseling',
	components: {
		BenefitingInfo: () => import('../benefitingInfo/index.vue'),
	},
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
</style>